@import '../../../../scss/theme-bootstrap';

.gnav-dropdown-formatter {
  // Support for nested menus with variable column layouts.
  &.gnav-dropdown-formatter--columns {
    .gnav-dropdown-formatter__content {
      flex-flow: row nowrap;
      justify-content: center;
      &-item {
        border-#{$rdirection}: solid 1px $color-white;
        border-#{$ldirection}: none;
        padding-#{$rdirection}: 0;
        padding-#{$ldirection}: 20px;
        width: auto;
        &:last-child {
          border: none;
        }
      }
    }
  }
}
